@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("./fonts.css");
@import url("./palette.css");
/* button.css */
input:checked ~ #toggle_dot {
    transform: translateX(100%);
    @apply dark:bg-primary-1-dark;
}
input#password:-webkit-autofill,
input#password:-webkit-autofill:hover,
input#password:-webkit-autofill:focus,
input#password:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #2C2B3A inset !important;
    -webkit-text-fill-color: white !important;
}

@tailwind base;
@tailwind components;

@tailwind utilities;

@layer components {
    .dropdown {
        @apply rounded-md focus:border-input-border-light dark:focus:border-input-border-dark-selected dark:border-search-bar-border-input-dark border-search-bar-border-input-light dark:bg-dropdown-bg-dark dark:text-white bg-dropdown-bg-light text-main-text-light;
    }

    .input {
        @apply rounded-md focus:border-input-border-light dark:focus:border-input-border-dark-selected dark:border-search-bar-border-input-dark border-search-bar-border-input-light dark:bg-dropdown-bg-dark dark:text-white bg-dropdown-bg-light text-main-text-light;
    }

    .input-sign-up-login {
        @apply shadow-none appearance-none w-full px-2 rounded-md dark:border-input-border-dark dark:bg-input-bg-dark dark:text-white dark:placeholder-input-text-placeholder-dark focus:ring-1 focus:ring-secondary-gradien1-dark;
    }

    .table-text-header {
        @apply text-sm font-semibold text-left align-text-top text-main-text-lighter-light dark:text-sub-text-dark;
    }

    .table-text-header-info-icon {
        @apply w-4 h-4 fill-current text-main-text-lighter-light dark:text-sub-text-dark;

    }

    .table-eye-icon {
        @apply self-center mr-2 w-5 h-5 fill-current text-main-text-lighter-light dark:text-sub-text-dark;
    }

    .table-base {
        @apply flex overflow-x-scroll flex-col rounded shadow-md dark:shadow bg-container-panel-bg-light dark:bg-container-panel-bg-dark;
    }

    .table-text-body {
        @apply dark:text-main-text-dark text-main-text-light;
    }

    .table-text-highlighted-body {
        @apply font-bold text-secondary-1-light dark:text-secondary-1-dark;
    }

    .table-text-live-price {
        @apply text-sm font-bold text-left dark:text-secondary-text-dark text-secondary-text-light;
    }

    .single-data-info-container {
        @apply px-4 py-10 rounded shadow-md dark:shadow bg-container-panel-bg-light dark:bg-container-panel-bg-dark;
    }

    .single-data-info-title {
        @apply mb-2 text-3xl text-center font-semibold font-bold dark:text-secondary-1-dark text-secondary-1-light;
    }

    .single-data-info-icon {
        @apply self-center ml-2 w-4 h-4 fill-current dark:text-sub-text-dark;
    }

    .simple-tab-selected {
        @apply font-semibold px-3 lg:px-9 py-2 shadow rounded truncate dark:text-main-text-light text-white dark:bg-white bg-tab-selected;
    }

    .simple-tab {
        @apply font-semibold px-3 lg:px-9 py-2 shadow rounded truncate dark:text-white text-tab-selected border border-[1px] border-tab-selected dark:border-main-text-dark bg-white dark:bg-main-text-light;
    }

    .list-eye-icon {
        @apply h-4 w-4 self-center mr-2 dark:text-white text-main-text-light fill-current;
    }

    .list-text-title {
        @apply dark:text-white text-main-text-light font-semibold text-base;
    }

    .list-text-base {
        @apply dark:text-white text-main-text-light font-medium text-xs;
    }

    .list-text-secondary {
        @apply dark:text-sub-text-lighter-dark text-sub-text-light text-xs;
    }

    .list-text-live-price {
        @apply text-sm dark:text-secondary-text-dark text-secondary-text-light text-left font-bold;
    }

    .list-text-wallet-exposure-percentage {
        @apply dark:text-white text-main-text-light font-medium text-xs m-auto text-center;
    }

    .list-dca-rounded {
        @apply m-auto flex items-center justify-center rounded-full text-white mb-1 w-8 h-8;
    }

    .text-legal {
        @apply text-white mb-2 text-base;
    }

    .title-legal {
        @apply text-white mb-2 font-semibold text-lg;
    }

    .text-payment-modal {
        @apply text-main-text-light;
    }

    .text-payment-modal-bank-data {
        @apply text-blue-text-light;
    }

    .text-highlighted-payment-modal {
        @apply text-main-text-light text-2xl;
    }


}